import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Label, Section, Text } from 'shared-library';
import { formatUnixDatetime } from 'packages/formatters';
import { buildStudySelector } from '../../store/studies/selectors';
import { selectStaff } from '../../store/staff/selectors';
import { Column, Row } from '../Layout';

const CheckinDetails = () => {
  const { studyId: selectedStudyId } = useParams();
  const selectedStudy = buildStudySelector(selectedStudyId);
  const study = useSelector(selectedStudy);
  const staff = useSelector(selectStaff);
  const checkinBy = study.status.checkinBy
    ? staff.data.filter((member) => member.userId === study.status.checkinBy)[0]
    : null;

  return (
    <Section title="Checkin">
      {!study.status.checkin && (
        <Row>
          <Text>This study is not currently marked as checked in.</Text>
        </Row>
      )}
      {study.status.checkin === true && (
        <Row>
          <Column>
            <Label>Checkin </Label>
            <Text>{study.status.checkin ? `Yes` : 'No'}</Text>
          </Column>
          <Column>
            <Label>Checkin time</Label>
            {study.status.checkinTime && (
              <Text>{formatUnixDatetime(study.status.checkinTime, study.location.timezone)}</Text>
            )}
            {!study.status.paidTime && <Text>N/A</Text>}
          </Column>
          <Column>
            <Label>Checkin by</Label>
            <Text>{checkinBy ? `${checkinBy.firstname} ${checkinBy.lastname}` : 'N/A'}</Text>
          </Column>
        </Row>
      )}
    </Section>
  );
};

export default CheckinDetails;
