import React, { useContext, useEffect, useRef, useState } from 'react';

import {
  Heading,
  Icon,
  colors,
  Section,
  TextInput,
  DatePicker,
  Button,
  LoadingSpinner,
  Notification,
} from 'shared-library';
import { StudyContext } from 'packages/booking-contexts';
import { PrescriptionContext } from 'packages/prescription/contexts';
import {
  BookingCalendar,
  LocationMachineSku,
  SalesNotes,
  SafetyQuestions,
  CheckinContext,
  SuppressEmailOrInvoice,
  CancellationModal,
} from 'packages/booking-components';
import { PaymentContext } from 'packages/payment';
import { updateSfOpportunity } from 'packages/sales-force';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useToasts } from 'react-toast-notifications';
import ReactTooltip from 'react-tooltip';
import { getEnvironment } from 'packages/locations';
import { fetchPiiResource } from 'packages/apis';
import { Column, Row } from '../Layout';
import { UnixTimestamp, AppointmentChange, Study } from '../../types';
import { buildPatientSelector } from '../../store/patients/selectors';
import { fetchPatientAccountSummary } from '../../store/patients/actions';
import {
  addOrChangeBooking,
  removeAppointment,
  updateStudySedativeStatus,
  updatePreferredPharmacyInfo,
  fetchStudy,
} from '../../store/studies/actions';
import labels from '../../labels';

const StyledReactTooltip = styled(ReactTooltip)`
  opacity: 1 !important;
  &:show {
    opacity: 1 !important;
  }
  .show {
    opacity: 1 !important;
  }
`;

export interface AddChangeBookingProps {
  onEditClick: () => void;
  study: Study & {
    referrer: { type: string };
    bookingTime: string;
    package: string;
    tags: Array<string>;
  };
  scrollToSafetyQuestions: boolean;
  setSedativeTaken: (sedativeTaken: boolean) => void;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${colors.smoke};
  border-radius: 3px;
  margin-top: 26px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: rgba(242, 243, 247, 0.6);
  min-height: 60px;
  padding: 0 14px;
  cursor: pointer;

  & > * {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    column-gap: 10px;
  }
`;

const Content = styled.div`
  padding: 16px;
`;

const ButtonRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 32px;
`;

const RemoveAppointmentButton = styled(Button)`
  width: 185px;
  padding: 11px 10px !important;
  margin: 21px auto;
`;

const AppointmentTimeDiv = styled.div<{ appointmentTime: any }>`
  padding: 12px;
  ${({ appointmentTime }) => !appointmentTime && `box-shadow: 0 0 10px ${colors.secondary}`}
`;

const SuppressEmailOrInvoiceContainer = styled.div`
  margin-top: 40px;
`;

export const AddChangeBooking: React.FC<AddChangeBookingProps> = ({
  onEditClick,
  study,
  scrollToSafetyQuestions,
  setSedativeTaken,
}: AddChangeBookingProps) => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const {
    checkinContext,
    updateCheckinContext,
    getRedux,
    changeInContext,
    requiredFields,
    setRequiredFields,
    submitSafetyQuestions,
  } = useContext(CheckinContext);

  const { prescriptionTaken, updatePrescriptionTaken } = useContext(PrescriptionContext);
  const {
    updateStudyContext,
    appointmentTime,
    status,
    salesNotes,
    isUpdate,
    booking,
    machineID,
    slotSelectionType,
  } = useContext(StudyContext);
  const { primaryAttribution } = status;
  const [safetyQuestionsExpanded, setSafetyQuestionsExpanded] = useState(
    !study.status?.medicalHistory,
  );
  const [invoiceAvailable, setInvoiceAvailable] = useState(!!study.status.invoiceAvailable);
  const [customerEmailAvailable, setCustomerEmailAvailable] = useState(
    !!study.status.customerEmailAvailable,
  );
  const originalAppointmentTime = study.booking.length && study.booking[0].utcStart;
  const originalLocation = study.location.locationID;
  const [skuID, setSkuID] = useState(study.items.primary.skuID);
  const [selectedLocation, setSelectedLocation] = useState(study.location);
  const [removeModal, setRemoveModal] = useState<boolean>(false);
  const [savingChanges, setSavingChanges] = useState<boolean>(false);
  const [removingAppointment, setRemovingAppointment] = useState<boolean>(false);
  const { price, invoiceSummary } = useContext(PaymentContext);

  const patient = useSelector(buildPatientSelector(study.patient));
  const isProactiveBookingStudy = useRef(study?.config?.proactiveBooking || false);

  const safetyQuestionsRef = useRef(null);
  const executeScroll = () => safetyQuestionsRef.current.scrollIntoView({ behavior: 'smooth' });

  useEffect(() => {
    if (scrollToSafetyQuestions) {
      setTimeout(() => {
        executeScroll();
      }, 200);
    }
  }, [scrollToSafetyQuestions]);

  useEffect(() => {
    const getAccountSummaryForPatient = async () => {
      try {
        await fetchPatientAccountSummary(patient.userId, study.studyID)(dispatch);
      } catch (err) {
        addToast(
          Notification.create(
            'Error fetching account summary',
            "Something unexpected went wrong when fetching this patient's account summary. Give it another try and please report an issue if this persists.",
          ),
          { appearance: 'error' },
        );
      }
    };
    if (patient && !patient.accountSummary) {
      getAccountSummaryForPatient();
    }
  }, []);

  const handleLocationMachineSkuChange = (change) => {
    if (change?.skuID) {
      setSkuID(change.skuID);
    }
    if (change?.selectedLocation) {
      setSelectedLocation(change.selectedLocation);
    }
    updateStudyContext({ ...change, appointmentTime: null });
  };

  const handleRemoveAppointment = async (cancellationDetail, cancellationReason) => {
    setRemovingAppointment(true);
    setRemoveModal(false);
    try {
      await removeAppointment(
        study.studyID,
        study.patient,
        study.location,
        cancellationDetail,
        cancellationReason,
        study.sfOpportunityID,
        customerEmailAvailable,
      )(dispatch);
      setRemovingAppointment(false);
      addToast(
        Notification.create(
          'Appointment Removed',
          `Successfully removed ${patient.firstname} ${patient.lastname[0]}'s appointment`,
        ),
        { appearance: 'success' },
      );
      onEditClick();
    } catch (err) {
      addToast(
        Notification.create(
          'Saving failed',
          `Something went wrong, and ${patient.firstname} ${patient.lastname[0]}'s appointment may not have been removed. Give it another try and please report an issue if this persists.`,
        ),
        { appearance: 'error' },
      );
    }
  };

  const errorSubmittingSafetyQuestions = () => {
    return addToast(
      Notification.create('Error submitting safety questions', 'Missing some required fields.'),
      { appearance: 'error' },
    );
  };

  const checkIfPrescriptionNeedsCancel = async () => {
    if (
      originalLocation !== selectedLocation.locationID &&
      study.practitioners?.prescribing?.prenuvoID
    ) {
      try {
        const { parsedBody: prescribingNP } = await fetchPiiResource(
          `/api/v1/userV2/${study.practitioners?.prescribing?.prenuvoID}`,
        );
        const selectedLocationInPrescriberLocations = prescribingNP?.prescriber_locations?.find(
          (countryState: string) => selectedLocation.locationID.includes(countryState),
        );
        if (!selectedLocationInPrescriberLocations) {
          return true;
        }
      } catch {
        // If there was an issue checking for NP's prescriber locations, show toast error and default to not cancelling
        addToast(
          Notification.create(
            'Error cancelling prescription request',
            `Something went wrong, and ${patient.firstname} ${patient.lastname[0]}'s prescription request may not have been cancelled when it needs to be cancelled. Give it another try and please report an issue if this persists.`,
          ),
          { appearance: 'error' },
        );
      }
    }
    return false;
  };

  const saveChanges = async () => {
    try {
      setSavingChanges(true);
      await updatePrescriptionTaken().then((response) => {
        if (response?.status === 200) {
          setSedativeTaken(prescriptionTaken);
        }
      });
      try {
        if (changeInContext) {
          const isProactiveStudyPaid = isProactiveBookingStudy.current && invoiceSummary.paid > 0;
          const ableToSkipSafetyQuestions =
            !isProactiveBookingStudy.current || isProactiveStudyPaid;

          const missingRequiredFields =
            requiredFields.map((field) => !checkinContext[field]).filter((field) => !!field)
              .length > 0;

          if (
            missingRequiredFields &&
            ((ableToSkipSafetyQuestions && Object.keys(checkinContext).length > 0) ||
              !ableToSkipSafetyQuestions)
          ) {
            return errorSubmittingSafetyQuestions();
          }

          submitSafetyQuestions(study.studyID);

          const sedativeStatus =
            checkinContext['Safety.SafetyQuestions.Claustrophobic.OralSedativePrescription'] ===
              'yes' && checkinContext['Safety.SafetyQuestions.Claustrophobic.Status'] === 'yes'
              ? 'Pending'
              : 'Not Requested';
          const medications =
            checkinContext['Safety.SafetyQuestions.Medications.Current'] === 'no'
              ? 'None Reported'
              : checkinContext['Safety.SafetyQuestions.Medications.Types'];
          const allergies =
            checkinContext['Safety.SafetyQuestions.Allergies.Status'] === 'no'
              ? 'None Reported'
              : checkinContext['Safety.SafetyQuestions.Allergies.Types'];
          const sedative = {
            status: sedativeStatus,
          };
          await updateStudySedativeStatus(study.studyID, { sedative, medications, allergies })(
            dispatch,
          );

          const preferredPharmacy = {
            name: checkinContext['Safety.SafetyQuestions.Prescription.PreferredPharmacyName'],
            address:
              checkinContext['Safety.SafetyQuestions.Prescription.PreferredPharmacyAddress1'],
            address2:
              checkinContext['Safety.SafetyQuestions.Prescription.PreferredPharmacyAddress2'],
            city: checkinContext['Safety.SafetyQuestions.Prescription.PreferredPharmacyCity'],
            state: checkinContext['Safety.SafetyQuestions.Prescription.PreferredPharmacyState'],
            country: checkinContext['Safety.SafetyQuestions.Prescription.PreferredPharmacyCountry'],
            postalCode:
              checkinContext['Safety.SafetyQuestions.Prescription.PreferredPharmacyPostalCode'],
            phone_number:
              checkinContext['Safety.SafetyQuestions.Prescription.PreferredPharmacyPhoneNumber'],
            fax_number:
              checkinContext['Safety.SafetyQuestions.Prescription.PreferredPharmacyFaxNumber'],
          };
          await updatePreferredPharmacyInfo(study.studyID, preferredPharmacy)(dispatch);

          if ('Safety.SafetyQuestions.Prescription.PatientHealthNumber' in checkinContext) {
            const phnPayload = {
              phn: checkinContext['Safety.SafetyQuestions.Prescription.PatientHealthNumber'],
            };
            if (phnPayload) {
              await fetchPiiResource(`/api/v1/userV2/${patient.userId}`, 'PATCH', phnPayload);
            }
          }
        }
      } catch (err) {
        addToast(
          Notification.create(
            'Saving failed',
            `Something went wrong, and the changes to ${patient.firstname} ${patient.lastname[0]}'s safety questions may not have been saved. Give it another try and please report an issue if this persists.`,
          ),
          { appearance: 'error' },
        );
      }

      const prescriptionNeedsCancel = await checkIfPrescriptionNeedsCancel();

      const appointmentChange: AppointmentChange = {
        newUtcStart: appointmentTime,
        machineID,
        skuID,
        location: selectedLocation,
      };
      const discountUpdated = await addOrChangeBooking(
        study.studyID,
        study.patient,
        appointmentChange,
        primaryAttribution,
        salesNotes,
        isUpdate,
        study.status.booked,
        booking?.utcStart,
        invoiceAvailable,
        customerEmailAvailable,
        slotSelectionType,
        prescriptionNeedsCancel,
        patient,
      )(dispatch);
      const environment = getEnvironment();
      const updatedStudy = await fetchStudy(study.studyID)(dispatch);
      if (selectedLocation.type !== 'whitelabel') {
        await updateSfOpportunity({
          study: {
            ...updatedStudy,
            prenuvoId: study?.patient?.prenuvoID || study?.patient,
            appointmentTime,
            stage: study.status.booked,
            location: selectedLocation,
            frontDeskUrl: `https://${
              environment === 'STAGING' ? 'staging.prenuvo' : 'www.prenuvo'
            }.com/admin/frontdesk/study/${study.studyID}`,
          },
          updateStudy: true,
          newOpportunity: false,
        });
      }
      addToast(
        Notification.create(
          'Saved successfully',
          `Successfully updated ${patient.firstname} ${patient.lastname[0]}'s booking details${
            discountUpdated
              ? ' and updated percentage discounts, and/or removed inapplicable discounts'
              : ''
          }.`,
        ),
        { appearance: 'success' },
      );
    } catch (err) {
      addToast(
        Notification.create(
          'Saving failed',
          `Something went wrong, and the changes to ${patient.firstname} ${patient.lastname[0]}'s appointment may not have been saved. Give it another try and please report an issue if this persists.`,
        ),
        { appearance: 'error' },
      );
    } finally {
      setSavingChanges(false);
      onEditClick();
    }
  };

  const suppressInvoiceFunction = (value: string) => {
    setInvoiceAvailable(value.includes('no-suppress'));
  };

  const suppressCustomerEmails = (value: string) => {
    setCustomerEmailAvailable(value.includes('no-suppress'));
  };

  return (
    <Section title="Study Details" onEditClick={onEditClick} viewState="edit" disabled={false}>
      <Heading size={7}>Member Information</Heading>
      <Row>
        <Column>
          <TextInput
            label="Member Name"
            disabled
            defaultValue={`${patient.firstname} ${patient.lastname[0]}`}
          />
        </Column>
        <Column>
          <DatePicker
            label="Date of Birth"
            disabled
            isOutsideRange={() => false}
            value={moment(patient.dob)}
          />
        </Column>
      </Row>
      <AppointmentTimeDiv appointmentTime={appointmentTime}>
        <Heading size={7}>Location, Scan Type, Date & Time</Heading>
        <LocationMachineSku onChange={handleLocationMachineSkuChange} />
        {removeModal && (
          <CancellationModal
            appointmentType={labels.packages[study.items.primary.skuID]}
            setRemoveModal={setRemoveModal}
            originalAppointmentTime={originalAppointmentTime}
            handleRemoveAppointment={handleRemoveAppointment}
          />
        )}
        <Heading size={7}>Date & Time</Heading>
        {skuID && (
          <BookingCalendar skuID={skuID}>
            <RemoveAppointmentButton
              variant="primary"
              size="medium"
              onClick={() => setRemoveModal(true)}
            >
              {removingAppointment ? <LoadingSpinner size="small" /> : 'Cancel Appointment'}
            </RemoveAppointmentButton>
          </BookingCalendar>
        )}
      </AppointmentTimeDiv>
      <SalesNotes />

      <Wrapper>
        <Header onClick={() => setSafetyQuestionsExpanded(!safetyQuestionsExpanded)}>
          <div>
            <Heading size={6} noMargin>
              Safety Questions
            </Heading>
          </div>
          <Icon type={safetyQuestionsExpanded ? 'minus' : 'plus'} color={colors.primary} />
        </Header>
        {safetyQuestionsExpanded && (
          <div ref={safetyQuestionsRef}>
            <Content>
              <SafetyQuestions
                studyID={study.studyID}
                piiContext={{ ...patient, patient: patient.prenuvoId }}
                checkinContext={checkinContext}
                updateCheckinContext={updateCheckinContext}
                getRedux={getRedux}
                setRequiredFields={setRequiredFields}
              />
            </Content>
          </div>
        )}
      </Wrapper>
      <SuppressEmailOrInvoiceContainer>
        <SuppressEmailOrInvoice
          invoiceAvailable={status.invoiceAvailable}
          customerEmailAvailable={status.customerEmailAvailable}
          suppressInvoiceFunction={suppressInvoiceFunction}
          suppressCustomerEmails={suppressCustomerEmails}
          studyId={study.studyID}
        />
      </SuppressEmailOrInvoiceContainer>
      <ButtonRow>
        <Button variant="secondary" size="medium" onClick={onEditClick}>
          Cancel
        </Button>
        <div data-tip="" data-for="appointmentTime">
          <Button
            variant="primary"
            size="medium"
            onClick={saveChanges}
            disabled={!appointmentTime || savingChanges}
          >
            {savingChanges ? <LoadingSpinner size="small" /> : 'Save Changes'}
          </Button>
          {!appointmentTime && (
            <StyledReactTooltip backgroundColor={colors.primary} id="appointmentTime">
              Please select an appointment time
            </StyledReactTooltip>
          )}
        </div>
      </ButtonRow>
    </Section>
  );
};
