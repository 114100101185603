/* eslint-disable prefer-destructuring */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useParams } from 'react-router-dom';

import { Label, Notification, Section, Text } from 'shared-library';
import { formatUnixDatetime, getTimeBetweenUnixTimestamp } from 'packages/formatters';
import PDFMerger from 'packages/pdf-merger';

import { buildStudySelector } from '../../store/studies/selectors';
import { selectStaff } from '../../store/staff/selectors';
import { Reading, Study } from '../../types';
import { captureError } from '../../lib';
import { Column, Row } from '../Layout';

const getLatestStructuredReading = (readings: Array<Reading>): Reading => {
  const versions = readings.map((r) => Number(r.version));
  const latestVersion = Math.max(...versions);
  return readings.find((r) => Number(r.version) === latestVersion);
};

const getReadingForStudy = (study: Study): Reading => {
  const isStructuredReadingPublished = study.readingPublished.some(
    (r) => r.reportType === 'structured',
  );
  const isTraditionalReadingPublished =
    study.traditionalReportAvailable &&
    study.readingPublished.some((r) => r.reportType === 'traditional');
  const isReadingTranscribed = study.status.transcribed;
  if (isStructuredReadingPublished && !isReadingTranscribed) {
    return getLatestStructuredReading(study.readingPublished);
  }
  if (isTraditionalReadingPublished) {
    return study.readingPublished.find((r) => r.reportType === 'traditional');
  }
  return null;
};

const reportTypeMap = {
  traditional: 'report-traditional',
  structured: 'report-user',
};

export const downloadPhysicianReportPdf = async (
  study: Study,
  reading: Reading,
): Promise<boolean> => {
  const studyId = study.studyID;
  const sku = study.booking[0].skuIDs[0];
  const reportType = reportTypeMap[reading.reportType];
  const pdfPath = `/radiologist/study/${studyId}/latest-pdf-report`;
  try {
    const ReportMerger = new PDFMerger(reportType, [study.patient], studyId, sku);
    await ReportMerger.handleDownload(pdfPath);
    return true;
  } catch (error) {
    captureError(error);
    throw error;
  }
};

export const downloadPhysicianReportWithAddendumPdf = async (
  study: Study,
  reading: Reading,
): Promise<boolean> => {
  const studyId = study.studyID;
  const sku = study.booking[0].skuIDs[0];
  const reportType = reportTypeMap[reading.reportType];
  const pdfPath = `/radiologist/study/${studyId}/latest-pdf-report-with-addendum`;
  try {
    const ReportMerger = new PDFMerger(reportType, [study.patient], studyId, sku);
    await ReportMerger.handleDownload(pdfPath);
    return true;
  } catch (error) {
    captureError(error);
    throw error;
  }
};

const Radiology = () => {
  const [isDownloadingReport, setDownloadingReport] = useState<boolean>(false);
  const [isDownloadingAddReport, setDownloadingAddReport] = useState<boolean>(false);
  const { studyId: selectedStudyId } = useParams();
  const selectStudy = buildStudySelector(selectedStudyId);
  const study = useSelector(selectStudy);
  const staff = useSelector(selectStaff);
  const radiologist = staff.data.filter((member) => member.userId === study.status.assignedTo)[0];
  const reading = getReadingForStudy(study);
  const { addToast } = useToasts();
  const isReportPublished = study.status.published;

  const handleDownloadReportClick = async (type) => {
    try {
      switch (type) {
        case 'structured':
          setDownloadingReport(true);

          await downloadPhysicianReportPdf(study, reading);
          setDownloadingReport(false);
          addToast(
            Notification.create('Report downloaded', 'Successfully downloaded report for study.'),
            { appearance: 'success' },
          );
          break;
        case 'addendum':
          setDownloadingAddReport(true);

          await downloadPhysicianReportWithAddendumPdf(study, reading);
          setDownloadingAddReport(false);
          addToast(
            Notification.create('Report downloaded', 'Successfully downloaded report for study.'),
            { appearance: 'success' },
          );
          break;
      }
    } catch (err) {
      setDownloadingReport(false);
      setDownloadingAddReport(false);
      addToast(
        Notification.create('Download failed', "Couldn't retrieve physician's report for study."),
        { appearance: 'error' },
      );
    }
  };

  const readBy = study.status.readBy
    ? staff.data.find((member) => member.userId === study.status.readBy)
    : study.status.assignedTo
    ? staff.data.find((member) => member.userId === study.status.assignedTo)
    : null;

  const qaDoneBy = study.status.reportQADoneBy
    ? staff.data.filter((member) => member.userId === study.status.reportQADoneBy)[0]
    : null;

  return (
    <Section title="Radiology">
      {reading && (
        <>
          <Row>
            <Column>
              <Label>Reading delay</Label>
              <Text>
                {study.status.readTime
                  ? `${getTimeBetweenUnixTimestamp(
                      study.booking[0].utcStart,
                      study.status.readTime,
                    )} days`
                  : 'N/A'}
              </Text>
            </Column>
            <Column>
              <Label>Reading time</Label>
              <Text>
                {study.status.readTime ? formatUnixDatetime(study.status.readTime) : 'N/A'}
              </Text>
            </Column>
            <Column>
              <Label>Read by</Label>
              <Text>{readBy ? `${readBy.firstname} ${readBy.lastname}` : 'N/A'}</Text>
            </Column>
          </Row>
          <Row>
            <Column>
              <Label>Prioritized</Label>
              <Text>{study.status.prioritized ? 'Yes' : 'No'}</Text>
            </Column>
            <Column>
              <Label>Urgent</Label>
              <Text>{study.status.urgent ? 'Yes' : 'No'}</Text>
            </Column>
            <Column>
              <Label>Sensitive</Label>
              <Text>{study.status.sensitive ? 'Yes' : 'No'}</Text>
            </Column>
          </Row>
          <Row>
            <Column>
              <Label>Report QA done</Label>
              <Text>{study.status.reportQADone ? 'Yes' : 'No'}</Text>
            </Column>
            <Column>
              <Label>QA Time</Label>
              <Text>
                {study.status.reportQADoneTime
                  ? formatUnixDatetime(study.status.reportQADoneTime)
                  : 'N/A'}
              </Text>
            </Column>
            <Column>
              <Label>QA Done By</Label>
              <Text>{qaDoneBy ? `${qaDoneBy.firstname} ${qaDoneBy.lastname}` : 'N/A'}</Text>
            </Column>
          </Row>
        </>
      )}
    </Section>
  );
};

export default Radiology;
