import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useParams } from 'react-router-dom';

import { Notification } from 'shared-library';
import { fetchAppsResource } from 'packages/apis';

import { buildStudySelector } from '../../store/studies/selectors';
import { captureError } from '../../lib';
import { buildPatientSelector } from '../../store/patients/selectors';
import { selectSession } from '../../store/session/selectors';

import Feedback from './Feedback';
import { selectStaff } from '../../store/staff/selectors';

const FeedbackContainer = () => {
  const { studyId: selectedStudyId } = useParams();
  const selectStudy = buildStudySelector(selectedStudyId);
  const study = useSelector(selectStudy);
  const { data: session } = useSelector(selectSession);
  const patient = useSelector(buildPatientSelector(study.patient));
  const staff = useSelector(selectStaff);
  const [isResendingFeedback, setIsResendingFeedback] = useState<boolean>(false);
  const [isResendingFeedbackAmplification, setIsResendingFeedbackAmplification] = useState<boolean>(
    false,
  );
  const [
    isFeedbackAmplificationDoneProcessing,
    setIsFeedbackAmplificationDoneProcessing,
  ] = useState<boolean>(false);
  const [
    isFeedbackAmplificationDeniedProcessing,
    setIsFeedbackAmplificationDeniedProcessing,
  ] = useState<boolean>(false);
  const [
    isFeedbackAmplificationResultProcessed,
    setIsFeedbackAmplificationResultProcessed,
  ] = useState<boolean>(false);
  const { addToast } = useToasts();

  const handleResendFeedbackClick = async () => {
    setIsResendingFeedback(true);
    try {
      const { email } = patient;
      await fetchAppsResource(`/study/${study.studyID}/feedback/reminder`);
      addToast(
        Notification.create(
          'Feedback email sent',
          `An email requesting feedback has been sent to ${email}`,
        ),
        { appearance: 'success' },
      );
      setIsResendingFeedback(false);
    } catch (err) {
      if (err.message !== 'BAD_REQUEST') {
        captureError(err);
      }
      const errorMessage =
        err.message === 'BAD REQUEST'
          ? 'Cannot resend feedback.'
          : 'Something unexpected went wrong. Give it another try and please report an issue if this persists.';
      addToast(Notification.create('Resend failed', errorMessage), { appearance: 'error' });
      setIsResendingFeedback(false);
    }
  };
  const handleResendFeedbackAmplificationClick = async () => {
    setIsResendingFeedbackAmplification(true);
    try {
      const { email } = patient;
      await fetchAppsResource(`/study/${study.studyID}/feedback/amplification`);
      addToast(
        Notification.create(
          'Feedback email sent',
          `An email requesting feedback amplification has been sent to ${email}`,
        ),
        { appearance: 'success' },
      );
      setIsResendingFeedbackAmplification(false);
    } catch (err) {
      if (err.message !== 'BAD_REQUEST') {
        captureError(err);
      }
      const errorMessage =
        err.message === 'BAD REQUEST'
          ? 'Cannot resend feedback amplification.'
          : 'Something unexpected went wrong. Give it another try and please report an issue if this persists.';
      addToast(Notification.create('Resend failed', errorMessage), { appearance: 'error' });
      setIsResendingFeedbackAmplification(false);
    }
  };
  const handleFeedbackAmplificationDoneClick = async () => {
    setIsFeedbackAmplificationDoneProcessing(true);
    try {
      await fetchAppsResource(`/study/${study.studyID}/status`, 'POST', {
        feedbackAmplificationDone: true,
      });
      addToast(
        Notification.create(
          'Feedback amplification done',
          `The feedback amplification was recorded as being complated by ${session.firstname} ${session.lastname}`,
        ),
        { appearance: 'success' },
      );
      setIsFeedbackAmplificationDoneProcessing(false);
      setIsFeedbackAmplificationResultProcessed(true);
    } catch (err) {
      if (err.message !== 'BAD_REQUEST') {
        captureError(err);
      }
      const errorMessage =
        err.message === 'BAD REQUEST'
          ? 'Cannot record request.'
          : 'Something unexpected went wrong. Give it another try and please report an issue if this persists.';
      addToast(Notification.create('Request failed', errorMessage), { appearance: 'error' });
      setIsFeedbackAmplificationDoneProcessing(false);
    }
  };
  const handleFeedbackAmplificationDeniedClick = async () => {
    setIsFeedbackAmplificationDeniedProcessing(true);
    try {
      await fetchAppsResource(`/study/${study.studyID}/status`, 'POST', {
        feedbackAmplificationDenied: true,
      });
      addToast(
        Notification.create(
          'Feedback amplification done',
          `The feedback amplification was recorded as being refused by ${session.firstname} ${session.lastname}`,
        ),
        { appearance: 'success' },
      );
      setIsFeedbackAmplificationDeniedProcessing(false);
      setIsFeedbackAmplificationResultProcessed(true);
    } catch (err) {
      if (err.message !== 'BAD_REQUEST') {
        captureError(err);
      }
      const errorMessage =
        err.message === 'BAD REQUEST'
          ? 'Cannot record request.'
          : 'Something unexpected went wrong. Give it another try and please report an issue if this persists.';
      addToast(Notification.create('Request failed', errorMessage), { appearance: 'error' });
      setIsFeedbackAmplificationDeniedProcessing(false);
    }
  };

  return (
    <Feedback
      isResendingFeedback={isResendingFeedback}
      onResendFeedbackClick={handleResendFeedbackClick}
      isResendingFeedbackAmplification={isResendingFeedbackAmplification}
      onResendFeedbackAmplificationClick={handleResendFeedbackAmplificationClick}
      isFeedbackAmplificationDoneProcessing={isFeedbackAmplificationDoneProcessing}
      onFeedbackAmplificationDoneClick={handleFeedbackAmplificationDoneClick}
      isFeedbackAmplificationDeniedProcessing={isFeedbackAmplificationDeniedProcessing}
      onFeedbackAmplificationDeniedClick={handleFeedbackAmplificationDeniedClick}
      isFeedbackAmplificationResultProcessed={isFeedbackAmplificationResultProcessed}
      study={study}
      staff={staff}
      feedback={study.feedback}
    />
  );
};

export default FeedbackContainer;
