/* eslint-disable prefer-destructuring */
import React from 'react';
import { Section, Text } from 'shared-library';

import { formatUnixDatetime } from 'packages/formatters';
import { Column, Label, Row } from '../Layout';
import { Study, Feedback, User } from '../../types';

interface FeedbackSectionProps {
  study: Study;
  staff: Array<User>;
  feedback: Feedback;
  isResendingFeedback: boolean;
  isResendingFeedbackAmplification: boolean;
  onResendFeedbackClick;
  onResendFeedbackAmplificationClick;
  onFeedbackAmplificationDoneClick;
  isFeedbackAmplificationDoneProcessing: boolean;
  onFeedbackAmplificationDeniedClick;
  isFeedbackAmplificationDeniedProcessing: boolean;
  isFeedbackAmplificationResultProcessed: boolean;
}

const FeedbackSection = ({
  study,
  staff,
  feedback,
  isResendingFeedback,
  isResendingFeedbackAmplification,
  onResendFeedbackClick,
  onResendFeedbackAmplificationClick,
  onFeedbackAmplificationDoneClick,
  isFeedbackAmplificationDoneProcessing,
  onFeedbackAmplificationDeniedClick,
  isFeedbackAmplificationDeniedProcessing,
  isFeedbackAmplificationResultProcessed,
}: FeedbackSectionProps) => {
  const feedbackAmplificationDoneBy = study.status.feedbackAmplificationDoneBy
    ? staff.data.find((member) => member.userId === study.status.feedbackAmplificationDoneBy)
    : null;
  const feedbackAmplificationDeniedBy = study.status.feedbackAmplificationDeniedBy
    ? staff.data.find((member) => member.userId === study.status.feedbackAmplificationDeniedBy)
    : null;

  return (
    <Section title="Feedback">
      {feedback && (
        <>
          {study.status.feedbackReceived && (
            <Row>
              <Text>
                Feedback received {formatUnixDatetime(study.status.feedbackReceivedTime)}.
              </Text>
            </Row>
          )}
          <Row>
            <Column>
              <Label>Cultivation/purchase</Label>
              <Text>{feedback.prescan ? feedback.prescan.replace(/:star:/g, '★') : 'N/A'}</Text>
            </Column>
            <Column>
              <Label>Checkin</Label>
              <Text>{feedback.checkin ? feedback.checkin.replace(/:star:/g, '★') : 'N/A'}</Text>
            </Column>
            <Column>
              <Label>Mri scan</Label>
              <Text>{feedback.mri ? feedback.mri.replace(/:star:/g, '★') : 'N/A'}</Text>
            </Column>
          </Row>
          <Row>
            <Column>
              <Label>Radiology</Label>
              <Text>{feedback.radiology ? feedback.radiology.replace(/:star:/g, '★') : 'N/A'}</Text>
            </Column>
            <Column>
              <Label>Overall experience</Label>
              <Text>
                {feedback.experience ? feedback.experience.replace(/:star:/g, '★') : 'N/A'}
              </Text>
            </Column>
            <Column>
              <Label>Would recommend</Label>
              <Text>{feedback.recommend ? feedback.recommend.replace(/:star:/g, '★') : 'N/A'}</Text>
            </Column>
          </Row>
          <Row>
            <Label>Feedback</Label>
          </Row>
          <Row>
            <Text>{feedback.feedback}</Text>
          </Row>
          <Row>
            <Column>
              <Label>Amplification</Label>
              {!study.status.feedbackAmplificationDone &&
                !study.status.feedbackAmplificationDenied && <Text>Not done</Text>}
              {study.status.feedbackAmplificationDone && <Text>Done</Text>}
              {study.status.feedbackAmplificationDenied && <Text>Denied</Text>}
            </Column>
            <Column>
              <Label>Time</Label>
              {!study.status.feedbackAmplificationDone &&
                !study.status.feedbackAmplificationDenied && <Text>N/A</Text>}
              {study.status.feedbackAmplificationDone && (
                <Text>
                  {formatUnixDatetime(
                    study.status.feedbackAmplificationDoneTime,
                    study.location.timezone,
                  )}
                </Text>
              )}
              {study.status.feedbackAmplificationDenied && (
                <Text>
                  {formatUnixDatetime(
                    study.status.feedbackAmplificationDeniedTime,
                    study.location.timezone,
                  )}
                </Text>
              )}
            </Column>
            <Column>
              <Label>Recorded by</Label>
              {!study.status.feedbackAmplificationDone &&
                !study.status.feedbackAmplificationDenied && <Text>N/A</Text>}
              {study.status.feedbackAmplificationDone && feedbackAmplificationDoneBy ? (
                <Text>
                  {feedbackAmplificationDoneBy.firstname} {feedbackAmplificationDoneBy.lastname}
                </Text>
              ) : (
                <Text>N/A</Text>
              )}
              {study.status.feedbackAmplificationDenied && feedbackAmplificationDeniedBy ? (
                <Text>
                  {feedbackAmplificationDeniedBy.firstname} {feedbackAmplificationDeniedBy.lastname}
                </Text>
              ) : (
                <Text>N/A</Text>
              )}
            </Column>
          </Row>
        </>
      )}
      {!feedback && (
        <>
          <Text>
            Last requested{' '}
            {study.status.feedbackRequestSent
              ? `${formatUnixDatetime(study.status.feedbackRequestSentTime)}.`
              : 'never via frontdesk.'}
          </Text>
        </>
      )}
    </Section>
  );
};

export default FeedbackSection;
