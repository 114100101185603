/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';

import { formatUnixDatetime } from 'packages/formatters';
import { colors, Link, Section, Tag, Text, Span } from 'shared-library';
import { BookingContextProvider, StudyContextProvider } from 'packages/booking-contexts';
import { CheckinContextProvider } from 'packages/booking-components';
import { getViewUrl } from 'packages/locations';
import { PaymentContextProvider } from 'packages/payment';
import { PrescriptionContextProvider } from 'packages/prescription/contexts';
import styled from 'styled-components';
import { Row, Column, Label as LayoutLabel } from '../Layout';
import { UnixTimestamp } from '../../types';
import { AddChangeBooking } from './AddChangeBooking';
import { ReferralType } from '../../enums/referralType.enum';

const Label = styled(LayoutLabel)`
  ${({ incomplete }) =>
    incomplete &&
    `
  &:after {
    content: '*';
    color: ${colors.secondary};
  }
    `}
`;

interface StudyInnerDetailsProps {
  onEditClick: () => void;
  editing: boolean;
  study: {
    practitioners: any;
    referrer: { type: string };
    bookingTime: string;
    package: string;
    sedativeTaken: boolean;
    sedative: {
      status: string;
      prescribedDate: string;
      details: {
        pickupDate: string;
        pharmacyRepresentativeName: string;
        preferredPharmacy: {
          name: string;
          address: string;
          address2: string;
          city: string;
          state: string;
          country: string;
          postalCode: string;
          phone_number: string;
          fax_number: string;
        };
      };
    };
    status: {
      booked: boolean;
      bookedTime: UnixTimestamp;
      medicalHistory: boolean;
      medicalHistoryTime: UnixTimestamp;
      referred: boolean;
      referredTime: UnixTimestamp;
      releasedToDoctor: boolean;
      releasedToDoctorTime: UnixTimestamp;
      releasedToPatient: boolean;
      releasedToPatientTime: UnixTimestamp;
      paid: boolean;
      paidTime: UnixTimestamp;
      published: boolean;
      publishedTime: UnixTimestamp;
      scanned: boolean;
      scannedTime: UnixTimestamp;
      researchConsent: boolean;
      customerEmailAvailable: boolean;
      invoiceAvailable: boolean;
    };
    studyID: string;
    tags: Array<string>;
    location: {
      address: {
        name: string;
      };
      timezone: string;
      referralRequired: boolean;
    };
    temporaryHold: boolean;
  };
  navigateToSafetyQuestions: boolean;
}

const getMachineName = (study) => {
  const assignedMachine = study?.location?.machines?.find(
    (location) => location?.machineID === study?.booking[0]?.machineID,
  );

  return assignedMachine?.name || '';
};

const getFormattedPharmacyAddress = (preferredPharmacy) => {
  if (preferredPharmacy) {
    const { address, city, state, country } = preferredPharmacy;
    return [address, city, state, country].filter(Boolean).join(', ');
  }
  return '-';
};

const SedativePrescriptionStatus = ({ study }: any) => {
  const [prescriptionStatusTag, setPrescriptionStatusTag] = useState({
    tag: '',
    text: '',
  });

  const getPrescriptionStatusTime = (prescriptionStatus: string) => {
    switch (prescriptionStatus) {
      case 'ready':
        return formatUnixDatetime(study.sedative.details.pickupDate, study.location.timezone);
      case 'sent':
        return formatUnixDatetime(study.sedative.details.sentDate, study.location.timezone);
      case 'prescribed':
      case 'pending':
        return study.sedative?.prescribedDate
          ? formatUnixDatetime(study.sedative.prescribedDate, study.location.timezone)
          : '';
      case 'not requested':
        return 'Not Requested';
      case 'ready-for-pharmacy':
        return study.sedative?.prescribedDate
          ? formatUnixDatetime(study.sedative.prescribedDate, study.location.timezone)
          : '';
      default:
        return '';
    }
  };

  const getSedativeStatusTag = async () => {
    const prescriptionStatus =
      study.practitioners?.prescribing?.prescriptionStatus.toLowerCase() ||
      study.sedative.status.toLowerCase();

    const prescriptionStatusMap = {
      ready: 'Ready for Pickup',
      sent: 'Sent to Pharmacy',
      prescribed: 'Prescribed',
      pending: 'Pending',
      'not requested': 'N/A',
      'ready-for-pharmacy': 'Ready for Pharmacy',
      cancelled: 'Cancelled',
    };

    const prescriptionStatusTime = getPrescriptionStatusTime(prescriptionStatus);

    if (prescriptionStatus) {
      return setPrescriptionStatusTag({
        tag: prescriptionStatusMap[prescriptionStatus],
        text: prescriptionStatusTime,
      });
    }
  };

  useEffect(() => {
    getSedativeStatusTag();
  }, [study]);

  return prescriptionStatusTag.tag ? (
    <Text>
      <Tag
        color={
          prescriptionStatusTag.tag === 'N/A'
            ? colors.gray
            : prescriptionStatusTag.tag === 'Prescribed'
            ? colors.primary
            : prescriptionStatusTag.tag === 'Sent to Pharmacy'
            ? colors.secondary
            : prescriptionStatusTag.tag === 'Ready for Pickup'
            ? colors.primary
            : prescriptionStatusTag.tag === 'Ready for Pharmacy'
            ? colors.primary
            : colors.secondary
        }
      >
        {prescriptionStatusTag.tag}
      </Tag>
      <Span>{prescriptionStatusTag.text}</Span>
    </Text>
  ) : null;
};

const StudyInnerDetails: React.FC<StudyInnerDetailsProps> = ({
  onEditClick,
  study,
  editing,
  navigateToSafetyQuestions,
}: StudyInnerDetailsProps) => {
  const [sedativeTaken, setSedativeTaken] = useState(study.sedativeTaken);

  if (editing) {
    return (
      <CheckinContextProvider>
        <BookingContextProvider>
          <StudyContextProvider>
            <PaymentContextProvider>
              <PrescriptionContextProvider>
                <AddChangeBooking
                  onEditClick={onEditClick}
                  study={study}
                  scrollToSafetyQuestions={navigateToSafetyQuestions}
                  setSedativeTaken={setSedativeTaken}
                />
              </PrescriptionContextProvider>
            </PaymentContextProvider>
          </StudyContextProvider>
        </BookingContextProvider>
      </CheckinContextProvider>
    );
  }
  return (
    <Section title="Study Details" onEditClick={onEditClick} viewState="edit">
      <div>
        <Row>
          {study.status.booked ? (
            <>
              <Column>
                <Label>Study Date/Time</Label>
                <Text>{study.bookingTime}</Text>
              </Column>
              <Column>
                <Label>Scan Location</Label>
                <Text>{study.location.address?.name}</Text>
              </Column>
              <Column>
                <Label>Package</Label>
                <Text>{study.package}</Text>
              </Column>
            </>
          ) : (
            <Column>
              <Text>No appointment booked</Text>
            </Column>
          )}
        </Row>
        <Row>
          <Column>
            <Label>Study ID</Label>
            <Text>{study.studyID}</Text>
          </Column>
          <Column>
            <Label>Machine</Label>
            <Text>{getMachineName(study)}</Text>
          </Column>
          <Column>
            <Label>Tags</Label>
            {study.tags.length ? (
              study.tags.map((tag) => (
                <Tag color={tag === 'PHYS 1ST' ? colors.secondary : colors.gray} key={tag}>
                  {tag}
                </Tag>
              ))
            ) : (
              <Text>N/A</Text>
            )}
          </Column>
        </Row>
        <Row>
          <Column>
            <Label incomplete={!study.status.booked}>Booked</Label>
            {study.status.booked ? (
              <Text>
                <Tag color={colors.success}>Completed</Tag>
                <Span>{formatUnixDatetime(study.status.bookedTime, study.location.timezone)}</Span>
              </Text>
            ) : (
              <Tag color={colors.error}>Incomplete</Tag>
            )}
          </Column>
          <Column>
            <Label>Reminded</Label>
            {study.status.reminded ? (
              <Text>
                <Tag color={colors.success}>Completed</Tag>
                <Span>
                  {formatUnixDatetime(study.status.remindedTime, study.location.timezone)}
                </Span>
              </Text>
            ) : (
              <Tag color={colors.error}>Incomplete</Tag>
            )}
          </Column>
          <Column>
            <Label incomplete={!study.status.referred}>
              {study?.location?.referralRequired
                ? ReferralType.MedicalReferral
                : ReferralType.AppropriatenessReview}
            </Label>
            {study.referrer.type === 'not-required' && !study.status.referred && (
              <Text>Not Required</Text>
            )}
            {study.status.referred && (
              <Text>
                <Tag color={colors.success}>Completed</Tag>
                <Span>{formatUnixDatetime(study.status.referredTime)}</Span>
              </Text>
            )}
            {!study.status.referred && study.referrer.type !== 'not-required' && (
              <Tag color={colors.error}>Incomplete</Tag>
            )}
          </Column>
        </Row>
        <Row>
          <Column>
            <Label incomplete={!(study.status.medicalHistory && study.status.consent)}>
              Medical History
            </Label>
            {study.status.medicalHistory ? (
              study.status.consent ? (
                <Text>
                  <Tag color={colors.success}>Completed</Tag>
                  <Span>{formatUnixDatetime(study.status.medicalHistoryTime)}</Span>
                </Text>
              ) : (
                <Tag color={colors.warning}>Consent Incomplete</Tag>
              )
            ) : (
              <Tag color={colors.error}>Incomplete</Tag>
            )}
          </Column>
          <Column>
            <Label incomplete={!study.status.paid}>Payment</Label>
            {study.status.paid ? (
              <Text>
                <Tag color={colors.success}>Completed</Tag>
                <Span>{formatUnixDatetime(study.status.paidTime)}</Span>
              </Text>
            ) : (
              <Tag color={colors.error}>Incomplete</Tag>
            )}
          </Column>
          <Column>
            <Label incomplete={!study.status.checkin}>Checkin</Label>
            {study.status.checkin ? (
              <Text>
                <Tag color={colors.success}>Completed</Tag>
                <Span>{formatUnixDatetime(study.status.checkinTime)}</Span>
              </Text>
            ) : (
              <Tag color={colors.error}>Incomplete</Tag>
            )}
          </Column>
        </Row>
        <Row>
          <Column>
            <Label>Imaging</Label>
            {study.status.scanned ? (
              <Text>
                <Tag color={colors.success}>Completed</Tag>
                <Span>{formatUnixDatetime(study.status.scannedTime)}</Span>
              </Text>
            ) : (
              <Tag color={colors.error}>Incomplete</Tag>
            )}
          </Column>
          <Column>
            <Label>Reading</Label>
            {study.status.published ? (
              <Text>
                <Tag color={colors.success}>Completed</Tag>
                <Span>{formatUnixDatetime(study.status.publishedTime)}</Span>
              </Text>
            ) : (
              <Tag color={colors.error}>Incomplete</Tag>
            )}
          </Column>
          <Column>
            <Label>Report QA</Label>
            {study.status.reportQADone ? (
              <Text>
                <Tag color={colors.success}>Completed</Tag>
                <Span>{formatUnixDatetime(study.status.reportQADoneTime)}</Span>
              </Text>
            ) : (
              <Tag color={colors.error}>Incomplete</Tag>
            )}
          </Column>
        </Row>
        <Row>
          <Column>
            <Label>Released To Member</Label>
            {study.status.releasedToPatient ? (
              <Text>
                <Tag color={colors.success}>Completed</Tag>
                <Span>{formatUnixDatetime(study.status.releasedToPatientTime)}</Span>
              </Text>
            ) : (
              <Tag color={colors.error}>Incomplete</Tag>
            )}
          </Column>
          <Column>
            <Label>Released To Doctor</Label>
            {study.status.releasedToDoctor ? (
              <Text>
                <Tag color={colors.success}>Completed</Tag>
                <Span>{formatUnixDatetime(study.status.releasedToDoctorTime)}</Span>
              </Text>
            ) : (
              <Tag color={colors.error}>Incomplete</Tag>
            )}
          </Column>
          <Column>
            <Label>Member consult</Label>
            {study.practitioners?.consulting?.scheduledStatus === 'complete' && (
              <Text>
                <Tag color={colors.success}>Completed</Tag>
                <Span>{formatUnixDatetime(study.practitioners?.consulting?.completedTime)}</Span>
              </Text>
            )}
            {study.practitioners?.consulting?.scheduledStatus === 'not complete' && (
              <Tag color={colors.error}>Incomplete</Tag>
            )}
            {study.practitioners?.consulting?.requestedStatus === 'not requested' && (
              <Text>
                <Tag color={colors.gray}>N/A</Tag>
                <Span>Not requested</Span>
              </Text>
            )}
          </Column>
        </Row>
        <Row>
          <Column>
            <Label>Sedative</Label>
            {sedativeTaken ? (
              <Text>
                <Tag color={colors.success}>Sedative Taken</Tag>
              </Text>
            ) : (
              <Tag color={colors.error}>No Sedative Taken</Tag>
            )}
          </Column>
          <Column>
            <Label>Temporary Hold</Label>
            <Text>
              <Tag>{study.temporaryHold ? 'Yes' : 'No'}</Tag>
            </Text>
          </Column>
        </Row>
        <Section title="" />
        <Row>
          <Column>
            <Label>Research Consent</Label>
            <Text>
              <Tag color={study.status.researchConsent ? colors.success : colors.error}>
                {study.status.researchConsent ? 'Granted' : 'Not Granted'}
              </Tag>
            </Text>
          </Column>
          <Column>
            <Label>Email Suppressed</Label>
            <Text>
              <Tag>{study.status.customerEmailAvailable ? 'No' : 'Yes'}</Tag>
            </Text>
          </Column>
          <Column>
            <Label>Invoice Suppressed</Label>
            <Text>
              <Tag>{study.status.invoiceAvailable ? 'No' : 'Yes'}</Tag>
            </Text>
          </Column>
        </Row>
        <Section title="Prescription Details" />
        {study.sedative.status !== 'Not Requested' ? (
          <div>
            <Row>
              <Column>
                <Label>Pharmacy Name</Label>
                <Text>
                  {study.sedative?.details?.preferredPharmacy?.name
                    ? study.sedative.details.preferredPharmacy.name
                    : '-'}
                </Text>
              </Column>
              <Column>
                <Label>Pharmacy Address</Label>
                <Text>{getFormattedPharmacyAddress(study.sedative.details.preferredPharmacy)}</Text>
              </Column>
              <Column>
                <Label>Pharmacy Phone</Label>
                <Text>
                  {study.sedative?.details?.preferredPharmacy?.phone_number
                    ? study.sedative.details.preferredPharmacy.phone_number
                    : '-'}
                </Text>
              </Column>
            </Row>
            <Row>
              <Column>
                <Label>Pickup Date and Time</Label>
                <Text>
                  {study.sedative?.details?.pickupDate
                    ? formatUnixDatetime(study.sedative.details.pickupDate)
                    : '-'}
                </Text>
              </Column>
              <Column>
                <Label>FAX****</Label>
                <Text>
                  {study.sedative?.details?.preferredPharmacy?.fax_number
                    ? study.sedative.details.preferredPharmacy.fax_number
                    : '-'}
                </Text>
              </Column>
              <Column>
                <Label>Sedative Status</Label>
                <SedativePrescriptionStatus study={study} />
              </Column>
            </Row>
            <Link
              href={`${getViewUrl('WEBSITE')}/admin/study/${study.studyID}/prescription`}
              target="_blank"
            >
              View Prescription
            </Link>
          </div>
        ) : (
          <Row>
            <Column>
              <Label>Sedative Status</Label>
              <SedativePrescriptionStatus study={study} />
            </Column>
          </Row>
        )}
      </div>
    </Section>
  );
};

export default StudyInnerDetails;
