import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Notification } from 'shared-library';

import { buildStudySelector } from '../../store/studies/selectors';
import labels from '../../labels';
import { Study } from '../../types';
import { formatBookingTimeString } from '../../lib';

import StudyInnerDetails from './StudyInnerDetails';
import { updateStatusSuccessAction } from '../../store/studies/actions';
import { fetchAppsResource } from 'packages/apis';
import { useToasts } from 'react-toast-notifications';

const possibleTags = {
  'tagR&D': 'R&D',
  'tagPHYS1ST': 'PHYS 1ST',
};

const getTags = (study: Study) => {
  const tags = Object.keys(study.status)
    .filter((tag) => study.status[tag] && tag in possibleTags)
    .map((tag) => possibleTags[tag]);
  if (study.config.proactiveBooking) {
    tags.push('Proactive');
  }
  return tags;
};

const resolveReleasedStatuses = (studyStatuses: Study['status']): Study['status'] => ({
  ...studyStatuses,
  releasedToDoctor: studyStatuses.releasedToDoctor || studyStatuses.tradReportReleasedToDoctor,
  releasedToDoctorTime:
    studyStatuses.releasedToDoctorTime || studyStatuses.tradReportReleasedToDoctorTime,
  releasedToPatient: studyStatuses.releasedToPatient || studyStatuses.tradReportReleasedToPatient,
  releasedToPatientTime:
    studyStatuses.releasedToPatientTime || studyStatuses.tradReportReleasedToPatientTime,
});

const StudyInnerDetailsContainer = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const history = useHistory();
  const [editing, setEdit] = useState(false);
  const { studyId: selectedStudyId } = useParams();
  const selectStudy = buildStudySelector(selectedStudyId);
  const study = useSelector(selectStudy);
  const navigateToSafetyQuestions = history?.location?.state?.toggleEditOnStudyTab || false;

  useEffect(() => {
    const fetchLatestStudy = async () => {
      try {
        if (selectedStudyId) {
          const studyResponse = await fetchAppsResource<Study>(`/study/${selectedStudyId}`);
          const newStudy = studyResponse.parsedBody;
          const newCustomerEmailAvailable = newStudy.status?.customerEmailAvailable;
          const newInvoiceAvailable = newStudy.status?.invoiceAvailable;

          // If attributes not included in study status, apply the default values
          if (
            study.status?.customerEmailAvailable === undefined &&
            newCustomerEmailAvailable === undefined
          ) {
            dispatch(
              updateStatusSuccessAction(selectedStudyId, {
                ...study.status,
                customerEmailAvailable: true,
              }),
            );
          }

          if (study.status?.invoiceAvailable === undefined && newInvoiceAvailable === undefined) {
            dispatch(
              updateStatusSuccessAction(selectedStudyId, {
                ...study.status,
                invoiceAvailable: true,
              }),
            );
          }

          // If attributes from request doesn't match the current attributes in states, update the new values
          if (
            typeof newCustomerEmailAvailable !== 'undefined' &&
            study.status?.customerEmailAvailable !== newCustomerEmailAvailable
          ) {
            dispatch(
              updateStatusSuccessAction(selectedStudyId, {
                ...study.status,
                customerEmailAvailable: newCustomerEmailAvailable,
              }),
            );
          }

          if (
            typeof newInvoiceAvailable !== 'undefined' &&
            study.status?.invoiceAvailable !== newInvoiceAvailable
          ) {
            dispatch(
              updateStatusSuccessAction(selectedStudyId, {
                ...study.status,
                invoiceAvailable: newInvoiceAvailable,
              }),
            );
          }
        }
      } catch (err) {
        const notificationTitle = 'Loading studies failed';
        let notificationMessage =
          'Something went wrong when trying to request these studies. Refresh to give it another try and please report an issue if this persists.';
        if (err.message.includes('FORBIDDEN')) {
          notificationMessage =
            'You do not seem to have permission to view studies from this location';
        }
        addToast(Notification.create(notificationTitle, notificationMessage), {
          appearance: 'error',
        });
      }
    };

    fetchLatestStudy();
  }, [selectedStudyId]);

  useEffect(() => {
    setEdit(navigateToSafetyQuestions);
  }, [navigateToSafetyQuestions]);

  const handleEditClick = () => {
    setEdit(!editing);
  };

  const [booking] = study.booking;
  let packageName = 'Not scheduled';
  if (booking) {
    packageName = labels.packages[booking.skuIDs[0]];
  }
  const formattedStudy = {
    ...study,
    bookingTime: formatBookingTimeString(booking, study.location.timezone),
    package: packageName,
    tags: getTags(study),
    sedativeTaken: study.sedativeTaken,
    status: resolveReleasedStatuses(study.status),
    studyID: study.studyID,
  };

  return (
    <StudyInnerDetails
      editing={editing}
      onEditClick={handleEditClick}
      study={formattedStudy}
      navigateToSafetyQuestions={navigateToSafetyQuestions}
    />
  );
};

export default StudyInnerDetailsContainer;
