import { Column, Row } from "../Layout";
import React from "react";
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { buildStudySelector } from "../../store/studies/selectors";
import { selectStaff } from "../../store/staff/selectors";
import { getEnvironment } from 'packages/locations'
import {
  Label,
  Link,
  Section,
  Text,
} from 'shared-library';
import { formatUnixDatetime } from "packages/formatters";

const ScanDetails = () => {
  const { studyId: selectedStudyId } = useParams();
  const selectedStudy = buildStudySelector(selectedStudyId);
  const study = useSelector(selectedStudy);
  const staff = useSelector(selectStaff);
  const imagesAcquiredBy = study.status.imagesAcquiredBy
    ? staff.data.filter((member) => member.userId === study.status.imagesAcquiredBy)[0]
    : null;
  let hiHost = null
  switch (getEnvironment()) {
    case 'DEV':
      hiHost = 'https://hi.dev.prenuvo.com';
      break;
    case 'STAGING':
      hiHost = 'https://hi.staging.prenuvo.com';
      break;
    case 'PRODUCTION':
      hiHost = 'https://hi.prenuvo.com';
      break;
    default:
      hiHost = 'http://hi.dev.localhost:3300';
  }
  const socialVideosLink = `${hiHost}/api/v1/study/${study.studyID}/prenuvo-scan-videos`
  return (
    <Section title="Scan">
      {study.status.scanned === false && (
        <Row>
          <Text>This study is not currently marked as scanned.</Text>
        </Row>
      )}
      {study.status.scanned === true && (
        <Row>
          <Column>
            <Label>Scanned</Label>
            <Text>
              Yes
            </Text>
          </Column>
          <Column>
            <Label>Scanned time</Label>
            <Text>
              {formatUnixDatetime(study.status.scannedTime, study.location.timezone)}
            </Text>
          </Column>
          <Column>
            <Label>Scanned by</Label>
            <Text>
              {imagesAcquiredBy ? `${imagesAcquiredBy.firstname} ${imagesAcquiredBy.lastname}` : 'Not recorded'}
            </Text>
          </Column>
        </Row>
      )}
      {study.status.read === true && (
        <Row>
          <Column>
            <Link href={socialVideosLink} download>
              Download social videos
            </Link>
          </Column>
        </Row>
      )}
    </Section>
  )

}

export default ScanDetails