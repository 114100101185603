import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useParams } from 'react-router-dom';

import { Notification, Section } from 'shared-library';
import { fetchAppsResource } from 'packages/apis';

import { buildPatientsSelector } from '../../store/patients/selectors';
import { fetchSharesForStudy } from '../../store/studies/actions';
import { buildStudySelector } from '../../store/studies/selectors';
import { StudyShare } from '../../store/studies/types';

import StudyShares from './StudyShares';

const StudySharesContainer = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { studyId: selectedStudyId } = useParams();
  const selectStudy = buildStudySelector(selectedStudyId);
  const study = useSelector(selectStudy);
  const [isLoadingShares, setLoadingShares] = useState(false);

  const fetchStudyShares = async () => {
    setLoadingShares(true);
    try {
      await fetchSharesForStudy(study.studyID)(dispatch);
      setLoadingShares(false);
    } catch (error) {
      setLoadingShares(false);
      addToast(
        Notification.create(
          "Couldn't retrieve shares",
          'Something unexpected happened when trying to retrieve shares for this study. Give it another try and file an issue if this persists.',
        ),
        { appearance: 'error' },
      );
    }
  };

  useEffect(() => {
    if (!study.shares) {
      fetchStudyShares();
    }
  }, []);

  const handleRevokeShareClick = async (share: StudyShare) => {
    setLoadingShares(true);
    try {
      await fetchAppsResource(`/study/${study.studyID}/admin_share/remove`, 'POST', {
        prenuvoID: share.receiver_prenuvo_id,
      });
      await fetchSharesForStudy(study.studyID)(dispatch);
      setLoadingShares(false);
    } catch (error) {
      setLoadingShares(false);
      addToast(
        Notification.create(
          "Couldn't revoke share",
          'Something unexpected happened when trying to revoke sharing for this study. Give it another try and file an issue if this persists.',
        ),
        { appearance: 'error' },
      );
    }
  };

  const receiverIds = study.shares && study.shares.map((_) => _.receiver_prenuvo_id);
  const receivers = useSelector(buildPatientsSelector(receiverIds));
  const sharesWithPii =
    study.shares &&
    study.shares.map((share) => {
      const receiver = receivers[share.receiver_prenuvo_id] || {
        firstname: 'Unknown',
        lastname: 'User',
      };
      const { firstname, lastname } = receiver;
      return {
        ...share,
        name: `${firstname} ${lastname}`,
      };
    });

  return (
    <Section loading={isLoadingShares} title="Sharing">
      {study.shares && (
        <StudyShares
          isLoadingShares={isLoadingShares}
          onRevokeShareClick={handleRevokeShareClick}
          shares={sharesWithPii}
        />
      )}
    </Section>
  );
};

export default StudySharesContainer;
