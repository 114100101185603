import React from 'react';
import {Button, Section, Text} from 'shared-library';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { formatUnixDatetime, titleCase } from 'packages/formatters';
import { Column, Label, Row } from '../Layout';
import { buildStudySelector } from '../../store/studies/selectors';
import { selectStaff } from '../../store/staff/selectors';

const Consultation = () => {
  const { studyId: selectedStudyId } = useParams();
  const selectStudy = buildStudySelector(selectedStudyId);

  const study = useSelector(selectStudy);
  const consultStatus =
    study?.practitioners?.consulting?.scheduledStatus ||
    study?.practitioners?.consulting?.requestedStatus;
  const staff = useSelector(selectStaff);
  const consultingNP = study?.practitioners?.consulting?.prenuvoID
    ? staff.data.find((member) => member.userId === study?.practitioners?.consulting?.prenuvoID)
    : null;
  const researcher = study?.status?.researchFollowUpBy
    ? staff.data.find((member) => member.userId === study.status.researchFollowUpBy)
    : null;

  const performFollowup = () => {
    window.open(`/patient/study/${selectedStudyId}/followup`);
  }

  return (
    <>
    <Section title="Consultation" disabled={false}>
      <Row>
        <Column>
          <Label>Consulting NP</Label>
          <Text>{`${consultingNP?.firstname} ${consultingNP?.lastname}`}</Text>
        </Column>
        <Column>
          <Label>Consulting Time</Label>
          <Text>
            {study?.practitioners?.consulting?.consultTime &&
              formatUnixDatetime(
                study?.practitioners?.consulting?.consultTime,
                study.location.timezone,
              )}
          </Text>
        </Column>
        <Column>
          <Label>Status</Label>
          <Text>{titleCase(consultStatus)}</Text>
        </Column>
      </Row>
    </Section>
    <Section title="6-12 month followup" disabled={false}>
      <Row>
        <>
        {!study.status.researchFollowUp && (
          <Column>
            <Button variant={'secondary'} size={'medium'} onClick={() => performFollowup()}>
              Perform patient followup
            </Button>
          </Column>
        )}
        {study.status.researchFollowUp && (
          <>
          <Column>
            <Label> 6-12 month followup conducted by</Label>
            <Text>{`${researcher?.firstname} ${researcher?.lastname}`}</Text>
          </Column>
          <Column>
            <Label>Followup Time</Label>
            <Text>
              { formatUnixDatetime(
                  study.status.researchFollowUpTime,
                  study.location.timezone,
              )}
            </Text>
          </Column>
          </>
        )}
        </>
      </Row>
    </Section>
    </>
  );
};

export default Consultation;
