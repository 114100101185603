import React from 'react';
import styled from 'styled-components';

import { formatUnixDatetime } from 'packages/formatters';
import { colors, Button, Text } from 'shared-library';

import { Row, Column } from '../Layout';
import { StudyShare } from '../../store/studies/types';

const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
`;

const TableHead = styled.thead`
  border-bottom: 1px solid ${colors.cloud};
`;

const TableHeadRow = styled.tr``;

const TableBody = styled.tbody``;

const TableBodyRow = styled.tr`
  &:nth-child(odd) {
    background-color: ${colors.white};
  }

  &:nth-child(even) {
    background-color: ${colors.pearl};
  }
`;

const TableHeader = styled.th`
  color: ${colors.charcoal};
  font-size: 12px;
  font-weight: 700;
  padding: 16px 20px;
  letter-spacing: 0.5px;
  line-height: 16px;
  text-align: left;
  text-transform: uppercase;
`;

interface TableCellProps {
  fixedWidth?: number;
  noPadding?: boolean;
}

const TableCell = styled.td<TableCellProps>`
  color: ${colors.ash};
  line-height: 30px;
  padding: ${({ noPadding }) => (noPadding ? '0 20px' : '20px 20px')};

  ${({ fixedWidth }) =>
    fixedWidth &&
    `
    width: ${fixedWidth}px;
  `}
`;

type StudyShareWithPii = StudyShare & {
  name: string;
};

interface StudySharesProps {
  isLoadingShares: boolean;
  onRevokeShareClick: (share: any) => void;
  shares: Array<StudyShareWithPii>;
}

const StudyShares: React.FC<StudyShareProps> = ({
  isLoadingShares,
  onRevokeShareClick,
  shares,
}: StudySharesProps) => (
  <Row>
    {!shares.length && (
      <Column>
        <Text>Nothing shared yet.</Text>
      </Column>
    )}
    {!!shares.length && (
      <Column full>
        <Table>
          <TableHead>
            <TableHeadRow>
              <TableHeader>Full Name</TableHeader>
              <TableHeader>Date</TableHeader>
              <TableHeader>Actions</TableHeader>
            </TableHeadRow>
          </TableHead>
          <TableBody>
            {shares.map((share) => (
              <TableBodyRow key={share.index}>
                <TableCell>{share.name}</TableCell>
                <TableCell>{formatUnixDatetime(share.timestamp)}</TableCell>
                <TableCell fixedWidth={100}>
                  <Button
                    disabled={isLoadingShares}
                    onClick={() => onRevokeShareClick(share)}
                    size="xsmall"
                    variant="secondary"
                  >
                    Revoke
                  </Button>
                </TableCell>
              </TableBodyRow>
            ))}
          </TableBody>
        </Table>
      </Column>
    )}
  </Row>
);

export default StudyShares;
