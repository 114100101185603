import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { formatUnixDatetime, getTimeBetweenUnixTimestamp } from 'packages/formatters';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment-timezone';

import { Notification, Heading, Label, Section, Text, Link as SLLink } from 'shared-library';

import { fetchLegacyResource, fetchPiiResource } from 'packages/apis';
import { getEnvironment } from 'packages/locations';

import styled from 'styled-components';
import { Row, Column } from '../Layout';
import { buildStudySelector } from '../../store/studies/selectors';
import { updateSf } from '../../store/studies/actions';
import { selectSalesforce } from '../../store/salesforce/selectors';
import { selectStaff } from '../../store/staff/selectors';

import BookingForm from './BookingForm';

import { captureError } from '../../lib';
import { buildPatientSelector } from '../../store/patients/selectors';
import { User } from '../../types';
import SalesforceTextInput from './SalesforceTextInput';

const Link = styled(SLLink)`
  padding-bottom: 16px;
`;

const BookingDetails = () => {
  const environment = getEnvironment();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [isSaving, setIsSaving] = useState(false);
  const [viewState, setViewState] = useState<'editing' | 'edit'>('edit');
  const [isResendingConfirmation, setIsResendingConfirmation] = useState(false);
  const [referringMember, setReferringMember] = useState(null);
  const { studyId: selectedStudyId } = useParams();
  const selectedStudy = buildStudySelector(selectedStudyId);
  const study = useSelector(selectedStudy);
  const staff = useSelector(selectStaff);
  const patient = useSelector(buildPatientSelector(study.patient));
  const salesforce = useSelector(selectSalesforce);
  const handleEditClick = () => setViewState('editing');
  const handleCancelEdit = () => setViewState('edit');

  const showEditButton = study.salesforce?.opportunity.loaded;

  const bookedBy = study.status.bookedBy
    ? staff.data.filter((member) => member.userId === study.status.bookedBy)[0]
    : null;
  const bookingConfirmationEmailSentBy = study.status.bookingConfirmationEmailSentBy
    ? staff.data.filter(
        (member) => member.userId === study.status.bookingConfirmationEmailSentBy,
      )[0]
    : null;

  const remindedBy = study.status.remindedBy
    ? staff.data.filter((member) => member.userId === study.status.remindedBy)[0]
    : null;
  const reminderEmailSentBy = study.status.reminderEmailSentBy
    ? staff.data.filter((member) => member.userId === study.status.reminderEmailSentBy)[0]
    : null;
  const reminderSMSSentBy = study.status.reminderSMSSentBy
    ? staff.data.filter((member) => member.userId === study.status.reminderSMSSentBy)[0]
    : null;

  useEffect(() => {
    const fetchSelectedUser = async () => {
      const response = await fetchPiiResource<User>(
        `/api/v1/userV2/${study.status.sourceMemberPrenuvoID}`,
      );
      const user = response.parsedBody;
      setReferringMember(user);
    };
    if (study.status.sourceMemberPrenuvoID) {
      fetchSelectedUser();
    }
  }, []);

  const handleBookingSubmit = async (form) => {
    try {
      setIsSaving(true);
      const newOpportunity = {
        ...study.salesforce?.opportunity,
        source: form.leadSource.value,
        LeadSource: form.leadSource.value,
        type: form.type.value,
        Type: form.type.value,
      };
      await updateSf(study.studyID, study.sfOpportunityID, newOpportunity)(dispatch);
      setViewState('editing');
    } catch (error) {
      throw new Error(error.message);
    } finally {
      setIsSaving(false);
    }
  };

  const handleResendConfirmationClick = async () => {
    setIsResendingConfirmation(true);
    try {
      const { email } = patient;
      await fetchLegacyResource(`/admin/study/${study.studyID}/resend-confirmation`);
      addToast(
        Notification.create(
          'Reconfirmation sent',
          `A reconfirmation email has been sent to ${email}`,
        ),
        { appearance: 'success' },
      );
      setIsResendingConfirmation(false);
    } catch (err) {
      if (err.message !== 'BAD_REQUEST') {
        captureError(err);
      }
      const errorMessage =
        err.message ||
        'Something unexpected went wrong. Give it another try and please report an issue if this persists.';
      addToast(Notification.create('Sending confirmation email failed', errorMessage), {
        appearance: 'error',
      });
      setIsResendingConfirmation(false);
    }
  };
  return (
    <Section
      title="Sale, booking and reminders"
      onEditClick={showEditButton && handleEditClick}
      viewState={viewState}
    >
      <Row>
        <Heading size={7}>Booking</Heading>
      </Row>
      <Row>
        <Column>
          <Label>Booking type</Label>
          {!study.status.bookedOnline && !study.status.bookedOffline && <Text>N/A</Text>}
          {study.status.bookedOnline && study.status.bookedOffline && (
            <Text>Booked/modified online and offline.</Text>
          )}
          {study.status.bookedOnline && <Text>Booking was made online.</Text>}
          {study.status.bookedOffline && <Text>Booking was made offline.</Text>}
        </Column>
        <Column>
          <Label>Booked by</Label>
          {study.status.bookedTime && (
            <>
              <Text after={0}>
                {formatUnixDatetime(study.status.bookedTime, study.location.timezone)}
              </Text>
              <Text>{bookedBy ? `${bookedBy?.firstname} ${bookedBy?.lastname}` : 'Patient'}</Text>
            </>
          )}
        </Column>
        <Column>
          <Label>Email confirmation sent</Label>
          {study.status.bookingConfirmationEmailSentTime ? (
            <>
              <Text after={0}>
                {formatUnixDatetime(
                  study.status.bookingConfirmationEmailSentTime,
                  study.location.timezone,
                )}
              </Text>
              <Text>
                {bookingConfirmationEmailSentBy
                  ? `${bookingConfirmationEmailSentBy?.firstname} ${bookingConfirmationEmailSentBy?.lastname}`
                  : 'System'}
              </Text>
            </>
          ) : (
            <Text>---</Text>
          )}
        </Column>
      </Row>
      <Row>
        <Link
          as="button"
          disabled={isResendingConfirmation}
          onClick={() => handleResendConfirmationClick()}
        >
          Resend booking confirmation
        </Link>
      </Row>
      <Row>
        <Heading size={7}>Sales</Heading>
      </Row>
      {!study.sfOpportunityID && (
        <>
          <Row>
            <Text>No salesforce opportunity linked to this study.</Text>
          </Row>
          <SalesforceTextInput />
        </>
      )}
      {study.sfOpportunityID && (
        <>
          {study.salesforce?.opportunity.loading && (
            <Row>
              <Text>The salesforce opportunity is loading.</Text>
            </Row>
          )}
          {study.salesforce?.opportunity.error && (
            <>
              <Row>
                <Text>There was an error loading the salesforce opportunity.</Text>
              </Row>
              <SalesforceTextInput />
            </>
          )}
          {study.salesforce?.opportunity.loaded && (
            <>
              {viewState === 'edit' && (
                <>
                  <Row>
                    <Column>
                      <Label>Lead created</Label>
                      <Text>
                        {study.salesforce.opportunity.CreatedDate
                          ? moment(study.salesforce.opportunity.CreatedDate).format(
                              'D MMM YYYY HH:mm',
                            )
                          : 'Straight to booking'}
                      </Text>
                    </Column>
                    <Column>
                      <Label>Study booked</Label>
                      <Text>
                        {formatUnixDatetime(study.status.bookedTime, study.location.timezone)}
                      </Text>
                    </Column>
                    <Column>
                      <Label>Pipeline time</Label>
                      <Text>
                        {study.salesforce.opportunity.CreatedDate
                          ? `${getTimeBetweenUnixTimestamp(
                              moment
                                .tz(
                                  moment.utc(study.salesforce.opportunity.CreatedDate),
                                  study.location.timezone,
                                )
                                .unix(),
                              study.status.bookedTime,
                            )} days`
                          : 'N/A'}
                      </Text>
                    </Column>
                  </Row>
                  <Row>
                    <Column>
                      <Label>Type</Label>
                      <Text>{study?.salesforce?.opportunity?.Scan_Type__c || 'Unknown'}</Text>
                    </Column>
                    <Column>
                      <Label>Source</Label>
                      <Text>{study?.salesforce?.opportunity?.LeadSource || 'Unknown'}</Text>
                    </Column>
                    <Column>
                      <Label>Acquisition method</Label>
                      <Text>{study?.salesforce?.opportunity?.Campaign__c || 'Unknown'}</Text>
                    </Column>
                  </Row>
                  <Row>
                    <Column>
                      <Label>Salesperson</Label>
                      <Text>
                        {salesforce?.data?.users?.find(
                          (user) => user.id === study?.salesforce?.opportunity?.OwnerId,
                        )?.name || 'Unknown'}
                      </Text>
                    </Column>
                    <Column>
                      <Label>Referring member</Label>
                      {referringMember && (
                        <Text>
                          {referringMember?.firstname} {referringMember?.lastname}
                        </Text>
                      )}
                      {!referringMember && <Text>N/A</Text>}
                    </Column>
                  </Row>
                </>
              )}
              {viewState === 'editing' && (
                <BookingForm
                  isSaving={isSaving}
                  opportunity={study.salesforce.opportunity}
                  fields={salesforce}
                  onSubmit={handleBookingSubmit}
                  onCancelEdit={handleCancelEdit}
                />
              )}
            </>
          )}
          {!study.salesforce?.opportunity.error && (
            <Row>
              <Link
                target="_blank"
                href={`https://${
                  environment !== 'PRODUCTION'
                    ? 'prenuvo--prenuvosb.lightning.force.com'
                    : 'prenuvo.lightning.force.com'
                }/lightning/r/Opportunity/${study.sfOpportunityID}/view`}
                disabled={!study.sfOpportunityID}
              >
                View opportunity on Salesforce
              </Link>
            </Row>
          )}
          <Row>
            <Heading size={7}>Reminder</Heading>
          </Row>
          <Row>
            <Column>
              <Label>Marked as reminded</Label>
              {study.status.remindedTime ? (
                <>
                  <Text after={0}>
                    {formatUnixDatetime(study.status.remindedTime, study.location.timezone)}
                  </Text>
                  <Text>
                    {study.status.remindedBy
                      ? `${remindedBy?.firstname} ${remindedBy?.lastname}`
                      : 'System'}
                  </Text>
                </>
              ) : (
                <Text>No</Text>
              )}
            </Column>
            <Column>
              <Label>Reminder email sent</Label>
              {study.status.reminderEmailSentTime ? (
                <>
                  <Text after={0}>
                    {formatUnixDatetime(
                      study.status.reminderEmailSentTime,
                      study.location.timezone,
                    )}
                  </Text>
                  <Text>
                    {study.status.reminderEmailSentBy
                      ? `${reminderEmailSentBy?.firstname} ${reminderEmailSentBy?.lastname}`
                      : 'System'}
                  </Text>
                </>
              ) : (
                <Text>---</Text>
              )}
            </Column>
            <Column>
              <Label>Reminder SMS sent</Label>
              {study.status.reminderSMSSentTime ? (
                <>
                  <Text after={0}>
                    {formatUnixDatetime(study.status.reminderSMSSentTime, study.location.timezone)}
                  </Text>
                  <Text>
                    {study.status.reminderSMSSentBy
                      ? `${reminderSMSSentBy?.firstname} ${reminderSMSSentBy?.lastname}`
                      : 'System'}
                  </Text>
                </>
              ) : (
                <Text>---</Text>
              )}
            </Column>
          </Row>
        </>
      )}
    </Section>
  );
};

export default BookingDetails;
