import React from 'react';

import Booking from './Booking';
import Checkin from './Checkin';
import Consultation from './Consultation';
import Feedback from './FeedbackContainer';
import PhysiciansReport from './PhysiciansReport';
import Radiology from './Radiology';
import ReferralMedicalHistory from './ReferralMedicalHistory';
import ReportFollowUp from './ReportFollowUp';
import Scan from './Scan';
import StudyInnerDetails from './StudyInnerDetailsContainer';
import StudyShares from './StudySharesContainer';

const StudyDetails = () => (
  <>
    <StudyInnerDetails />
    <PhysiciansReport />
    <Booking />
    <ReferralMedicalHistory />
    <Checkin />
    <Scan />
    <Radiology />
    <ReportFollowUp />
    <StudyShares />
    <Feedback />
    <Consultation />
  </>
);

export default StudyDetails;
