import React from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import PDFMerger from 'packages/pdf-merger';

import { Link, Heading, Section, Text } from 'shared-library';
import { formatUnixDatetime } from 'packages/formatters';
import { Row, Column, Label } from '../Layout';
import { buildStudySelector } from '../../store/studies/selectors';
import { selectStaff } from '../../store/staff/selectors';
import { selectSession } from '../../store/session/selectors';
import { captureError } from '../../lib';
import { getEnvironment } from 'packages/locations';

const ReferralMedicalHistory = () => {
  const { studyId: selectedStudyId } = useParams();
  const selectStudy = buildStudySelector(selectedStudyId);
  const study = useSelector(selectStudy);
  const staff = useSelector(selectStaff);
  const { data: session } = useSelector(selectSession);
  // TODO: Remove env check after implementing the new Auth0 env
  const isNonProdEnvironment = getEnvironment() !== 'PRODUCTION';
  const hasHiPermission = session.roles.includes('hi') || isNonProdEnvironment;

  const referredBy = study.status.referredBy
    ? staff.data.filter((member) => member.userId === study.status.referredBy)[0]
    : null;
  const reminderMedicalHistorySMSSentBy = study.status.reminderMedicalHistorySMSSentBy
    ? staff.data.filter(
        (member) => member.userId === study.status.reminderMedicalHistorySMSSentBy,
      )[0]
    : null;
  const reminderMedicalHistoryEmailSentBy = study.status.reminderMedicalHistoryEmailSentBy
    ? staff.data.filter(
        (member) => member.userId === study.status.reminderMedicalHistoryEmailSentBy,
      )[0]
    : null;

  const downloadReferral = async () => {
    try {
      const MedicalReferralMerger = new PDFMerger(
        'medical-referral',
        [study.patient, study.referrer.provider.prenuvoID, study.referrer.provider.providerID],
        study.studyID,
      );
      await MedicalReferralMerger.downloadPdfAsJpg();
      return true;
    } catch (error) {
      captureError(error);
      throw error;
    }
  };

  const downloadConsent = async () => {
    try {
      const consentMerger = new PDFMerger('patient-consent', [study.patient], study.studyID);
      await consentMerger.handleDownload();
      return true;
    } catch (error) {
      captureError(error);
      throw error;
    }
  };

  return (
    <>
      <Section title="Medical History">
        <Heading size={7}>Medical history</Heading>
        <Row>
          <Column>
            <Label>MH Completed</Label>
            <Text>
              {study.status.medicalHistoryTime
                ? formatUnixDatetime(study.status.medicalHistoryTime)
                : 'Not completed'}
            </Text>
          </Column>
          <Column>
            <Label>MH Reminder email sent</Label>
            {study.status.reminderMedicalHistoryEmailSentTime ? (
              <>
                <Text after={0}>
                  {formatUnixDatetime(
                    study.status.reminderMedicalHistoryEmailSentTime,
                    study.location.timezone,
                  )}
                </Text>
                <Text>
                  {study.status.reminderMedicalHistoryEmailSentBy
                    ? `${reminderMedicalHistoryEmailSentBy.firstname} ${reminderMedicalHistoryEmailSentBy.lastname}`
                    : 'System'}
                </Text>
              </>
            ) : (
              <Text>---</Text>
            )}
          </Column>
          <Column>
            <Label>MH Reminder SMS sent</Label>
            {study.status.reminderMedicalHistorySMSSentTime ? (
              <>
                <Text after={0}>
                  {formatUnixDatetime(
                    study.status.reminderMedicalHistorySMSSentTime,
                    study.location.timezone,
                  )}
                </Text>
                <Text>
                  {study.status.reminderMedicalHistorySMSSentBy
                    ? `${reminderMedicalHistorySMSSentBy.firstname} ${reminderMedicalHistorySMSSentBy.lastname}`
                    : 'System'}
                </Text>
              </>
            ) : (
              <Text>---</Text>
            )}
          </Column>
        </Row>
        {study.status.medicalHistory && (
          <Row>
            {hasHiPermission && (
              <Column>
                <Link
                  target="_blank"
                  href={`/admin/study/${study.studyID}/medical-history/radiologist`}
                >
                  View medical history
                </Link>
              </Column>
            )}
            <Column>
              {(study.memberConsentSigned || study.technologistConsentSigned) && (
                <Link onClick={downloadConsent}>Download member agreement and consent</Link>
              )}
            </Column>
          </Row>
        )}
      </Section>
    </>
  );
};

export default ReferralMedicalHistory;
