/* eslint-disable prefer-destructuring */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useParams } from 'react-router-dom';

import { colors, Link, LoadingSpinner, Notification, Text } from 'shared-library';
import { formatCapitalizedFirstLetter } from 'packages/formatters';

import PDFMerger from 'packages/pdf-merger';

import { buildStudySelector } from '../../store/studies/selectors';
import { Reading, Study } from '../../types';
import { captureError } from '../../lib';
import { Column, Row } from '../Layout';
import { selectSession } from '../../store/session/selectors';
import { getEnvironment } from 'packages/locations';

const getLatestStructuredReading = (readings: Array<Reading>): Reading => {
  const versions = readings.map((r) => Number(r.version));
  const latestVersion = Math.max(...versions);
  return readings.find((r) => Number(r.version) === latestVersion);
};

const getReadingForStudy = (study: Study): Reading => {
  const isStructuredReadingPublished = study.readingPublished.some(
    (r) => r.reportType === 'structured',
  );
  const isTraditionalReadingPublished =
    study.traditionalReportAvailable &&
    study.readingPublished.some((r) => r.reportType === 'traditional');
  const isReadingTranscribed = study.status.transcribed;
  if (isStructuredReadingPublished && !isReadingTranscribed) {
    return getLatestStructuredReading(study.readingPublished);
  }
  if (isTraditionalReadingPublished) {
    return study.readingPublished.find((r) => r.reportType === 'traditional');
  }
  return null;
};

const reportTypeMap = {
  traditional: 'report-traditional',
  structured: 'report-user',
};

const downloadPhysicianReportPdf = async (study: Study, reading: Reading): Promise<boolean> => {
  const studyId = study.studyID;
  const sku = study.booking[0].skuIDs[0];
  const reportType = reportTypeMap[reading.reportType];
  const pdfPath = `/radiologist/study/${studyId}/latest-pdf-report`;
  try {
    const ReportMerger = new PDFMerger(reportType, [study.patient], studyId, sku);
    await ReportMerger.handleDownload(pdfPath);
    return true;
  } catch (error) {
    captureError(error);
    throw error;
  }
};

const downloadPhysicianReportWithAddendumPdf = async (
  study: Study,
  reading: Reading,
): Promise<boolean> => {
  const studyId = study.studyID;
  const sku = study.booking[0].skuIDs[0];
  const reportType = reportTypeMap[reading.reportType];
  const pdfPath = `/radiologist/study/${studyId}/latest-pdf-report-with-addendum`;
  try {
    const ReportMerger = new PDFMerger(reportType, [study.patient], studyId, sku);
    await ReportMerger.handleDownload(pdfPath);
    return true;
  } catch (error) {
    captureError(error);
    throw error;
  }
};

const PhysiciansReport = () => {
  const [isDownloadingReport, setDownloadingReport] = useState<boolean>(false);
  const [isDownloadingAddReport, setDownloadingAddReport] = useState<boolean>(false);
  const { studyId: selectedStudyId } = useParams();
  const selectStudy = buildStudySelector(selectedStudyId);
  const study = useSelector(selectStudy);
  const { data: session } = useSelector(selectSession);

  // TODO: Remove env check after implementing the new Auth0 env
  const isNonProdEnvironment = getEnvironment() !== 'PRODUCTION';
  const isHiPermission = session.roles.includes('hi') || isNonProdEnvironment;
  const reading = getReadingForStudy(study);
  const shouldDownloadReport = reading && isHiPermission;
  const { addToast } = useToasts();
  const isReportPublished = study.status.published;

  const handleDownloadReportClick = async (type) => {
    try {
      switch (type) {
        case 'structured':
          setDownloadingReport(true);

          await downloadPhysicianReportPdf(study, reading);
          setDownloadingReport(false);
          addToast(
            Notification.create('Report downloaded', 'Successfully downloaded report for study.'),
            { appearance: 'success' },
          );
          break;
        case 'addendum':
          setDownloadingAddReport(true);

          await downloadPhysicianReportWithAddendumPdf(study, reading);
          setDownloadingAddReport(false);
          addToast(
            Notification.create('Report downloaded', 'Successfully downloaded report for study.'),
            { appearance: 'success' },
          );
          break;
      }
    } catch (err) {
      setDownloadingReport(false);
      setDownloadingAddReport(false);
      addToast(
        Notification.create('Download failed', "Couldn't retrieve physician's report for study."),
        { appearance: 'error' },
      );
    }
  };

  return (
    <>
      {shouldDownloadReport && (
        <>
          <Row>
            <Column>
              <Link onClick={() => handleDownloadReportClick('structured')}>
                {isDownloadingReport ? (
                  <LoadingSpinner color={colors.primary} size="small" />
                ) : (
                  `Download ${formatCapitalizedFirstLetter(
                    reading.reportType === 'structured' ? 'physician' : reading.reportType,
                  )} Report`
                )}
              </Link>
            </Column>
            <Column />
            {reading.reportType === 'structured' && (
              <Column>
                <Link onClick={() => handleDownloadReportClick('addendum')}>
                  {isDownloadingAddReport ? (
                    <LoadingSpinner color={colors.primary} size="small" />
                  ) : (
                    `Download Physician Report with Addendum`
                  )}
                </Link>
              </Column>
            )}
          </Row>
        </>
      )}
      {!reading && (
        <>
          <Row>
            {isReportPublished && (
              <Text>We&apos;re missing a report for this study, please report an issue.</Text>
            )}
            {!isReportPublished && <Text>Nothing published yet.</Text>}
          </Row>
        </>
      )}
    </>
  );
};

export default PhysiciansReport;
