/* eslint-disable prefer-destructuring */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Heading, Section, Text, Label, Button } from 'shared-library';
import {
  formatUnixDatetime,
  getMomentFromUnixTimestamp,
  getTimeBetweenUnixTimestamp,
} from 'packages/formatters';

import { buildStudySelector } from '../../store/studies/selectors';
import { selectStaff } from '../../store/staff/selectors';
import { Row, Column } from '../Layout';
import ScheduleConsult from './ScheduleConsult';
import SendStudyTracking from './SendStudyTracking';
import { updateStatus } from '../../store/studies/actions';
import { selectSession } from '../../store/session/selectors';

const ReportFollowUp = () => {
  const dispatch = useDispatch();
  const { studyId: selectedStudyId } = useParams();
  const selectStudy = buildStudySelector(selectedStudyId);
  const study = useSelector(selectStudy);
  const staff = useSelector(selectStaff);
  const { data: session } = useSelector(selectSession);
  const releasedToPatientBy = study.status.releasedToPatientBy
    ? staff.data.filter((member) => member.userId === study.status.releasedToPatientBy)[0]
    : null;
  const patientReportNotificationBy = study.status.patientReportNotificationBy
    ? staff.data.filter((member) => member.userId === study.status.patientReportNotificationBy)[0]
    : null;
  const hasReferringPractitioner = study.practitioners.referring?.practitionerID;
  const showProviderFollowUp = hasReferringPractitioner || study.status.providerAdded;

  const handleProviderNotificationComplete = async () => {
    await updateStatus(
      study.studyID,
      {
        providerReportNotification: true,
      },
      session.userId,
    )(dispatch);
  };
  const providerReportNotificationBy = study.status.providerReportNotificationBy
    ? staff.data.filter((member) => member.userId === study.status.providerReportNotificationBy)[0]
    : null;
  const shouldDisplayScheduleConsult =
    study.practitioners?.consulting?.requestedStatus === 'requested' ||
    !!study.practitioners?.consulting?.scheduledStatus;

  return (
    <Section title="Report followup">
      <Row>
        <Heading size={7}>Patient followup</Heading>
      </Row>
      {!study.status.published && (
        <Row>
          <Text>The report has not been published yet.</Text>
        </Row>
      )}
      {study.status.published && !study.status.releasedToPatient && (
        <Row>
          <Text>The report has not yet been released to the patient.</Text>
        </Row>
      )}
      {study.status.releasedToPatient && (
        <>
          <Row>
            <Column>
              <Label>Release delay</Label>
              <Text>
                {study.status.releasedToPatientTime
                  ? `${getTimeBetweenUnixTimestamp(
                      study.booking[0].utcStart,
                      study.status.releasedToPatientTime,
                    )} days`
                  : 'N/A'}
              </Text>
            </Column>
            <Column>
              <Label>Release time</Label>
              <Text>
                {study.status.releasedToPatientTime
                  ? formatUnixDatetime(study.status.releasedToPatientTime, study.location.timezone)
                  : 'N/A'}
              </Text>
            </Column>
            <Column>
              <Label>Released by</Label>
              <Text>
                {releasedToPatientBy
                  ? `${releasedToPatientBy.firstname} ${releasedToPatientBy.lastname}`
                  : 'N/A'}
              </Text>
            </Column>
          </Row>
          <Row>
            <Column>
              <Label>Patient notified</Label>
              {study.status.patientReportNotification && <Text>Yes</Text>}
              {!study.status.patientReportNotification && <Text>No</Text>}
            </Column>
            <Column>
              <Label>Notification time</Label>
              <Text>
                {study.status.patientReportNotificationTime
                  ? formatUnixDatetime(
                      study.status.patientReportNotificationTime,
                      study.location.timezone,
                    )
                  : 'N/A'}
              </Text>
            </Column>
            <Column>
              <Label>Notification by</Label>
              <Text>
                {patientReportNotificationBy
                  ? `${patientReportNotificationBy.firstname} ${patientReportNotificationBy.lastname}`
                  : 'N/A'}
              </Text>
            </Column>
          </Row>
        </>
      )}

      {shouldDisplayScheduleConsult && (
        <ScheduleConsult shouldDisplayScheduleConsult={shouldDisplayScheduleConsult} />
      )}

      <Row>
        <Heading size={7}>Provider followup</Heading>
      </Row>
      {showProviderFollowUp && (
        <>
          <Row>
            <SendStudyTracking />
          </Row>
          <Row>
            <>
              {!study.status.providerReportNotification && (
                <Button
                  onClick={() => handleProviderNotificationComplete()}
                  variant="primary"
                  size="medium"
                >
                  Mark notification complete
                </Button>
              )}
              {study.status.providerReportNotification && (
                <Text>
                  Provider notification was completed by {providerReportNotificationBy?.firstname}{' '}
                  {providerReportNotificationBy?.lastname} on{' '}
                  {getMomentFromUnixTimestamp(study.status.providerReportNotificationTime).format(
                    'DD MMM YYYY',
                  )}{' '}
                  at{' '}
                  {getMomentFromUnixTimestamp(study.status.providerReportNotificationTime).format(
                    'hh:mma',
                  )}
                  .
                </Text>
              )}
            </>
          </Row>
        </>
      )}
      {!showProviderFollowUp && (
        <Row>
          <Text>There are no providers for this study.</Text>
        </Row>
      )}
      {!study.status.published && (
        <Row>
          <Text>The study has not been published yet.</Text>
        </Row>
      )}
    </Section>
  );
};

export default ReportFollowUp;
